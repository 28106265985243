import { api, apiFile } from '../../services/httpclient';
import axios from 'axios';

const state = {
    token: null,
    user: {}
};

const getters = {
    AUTHENTICATED: (state) => state.token == null ? false : true,
    AUTH_USER: (state) => state.user,
    PLANS: (state) => state.user.plans ? state.user.plans.find((index) => { return index.pivot.status == 1}) : null
};

const mutations = {
    SET_TOKEN(state, payload) {
        state.token = payload;
        localStorage.setItem("token", payload);
        api.defaults.headers.common[
            "Authorization"
        ] = `Bearer ${payload}`;
        apiFile.defaults.headers.common[
            "Authorization"
        ] = `Bearer ${payload}`;
    },
    CLEAR_TOKEN(state) {
        state.token = null;
        localStorage.clear();
        delete api.defaults.headers.common['Authorization'];
    },
    SET_AUTH_USER: (state, payload) => state.user = payload
};

const actions = {

    async LOGIN({ commit, dispatch }, payload) {
        try {
            const response = await api.post(`api/v1/auth/login`, payload);
            commit("SET_TOKEN", response.data.data.token);
            commit("SET_AUTH_USER", response.data.data.user);
            dispatch("profile/GET_NOTIFICATIONS", {}, { root: true });
            return response;
        } catch (error) {
            return Promise.reject(error);
        }
    },

    async LOGIN_BACK({ commit, dispatch }, payload) {
        try {
            const response = await api.post(`api/v1/auth/login_user`, payload);
            commit("SET_TOKEN", response.data.data.token);
            commit("SET_AUTH_USER", response.data.data.user);
            dispatch("profile/GET_NOTIFICATIONS", {}, { root: true });
            return response;
        } catch (error) {
            return Promise.reject(error);
        }
    },

    async ME({ commit }, payload) {
        try {
            const response = await api.get(`api/v1/auth/me`, payload);
            commit("SET_AUTH_USER", response.data.data);
            return response;
        } catch (error) {
            return Promise.reject(error);
        }
    },

    async TRY_AUTO_LOGIN({ commit, dispatch }) {
        const token = localStorage.getItem("token");
        if (token != null) {
            commit("SET_TOKEN", token);
            dispatch("ME");
            dispatch("profile/GET_NOTIFICATIONS", {}, { root: true });
            return true;
        } else {
            return false;
        }
    },

    async REGISTER(_, payload) {
        try {
            const response = await api.post(`api/v1/auth/signup`, payload);
            return response;
        } catch (error) {
            return Promise.reject(error);
        }
    },

    async RECOVERY_CODE(_, payload) {
        try {
            const response = await api.post(`api/v1/password/create`, payload);
            return response;
        } catch (error) {
            return Promise.reject(error);
        }
    },

    async CONFIRM_CODE(_, payload) {
        try {
            const response = await api.get(
                `api/v1/password/find/${payload.code}`
            );
            return response;
        } catch (error) {
            return Promise.reject(error);
        }
    },

    async RESET_PASSWORD(_, payload) {
        try {
            const response = await api.post(
                `/api/v1/password/reset`,
                payload
            );
            return response;
        } catch (error) {
            return Promise.reject(error);
        }
    },

    async UPDATE_PROFILE({ commit }, payload) {
        try {
            const response = await api.put(`api/v1/auth/update`, payload);
            commit("SET_AUTH_USER", response.data.data);
            return response;
        } catch (error) {
            return Promise.reject(error);
        }
    },

    async LOGOUT({ commit }) {
        try {
            const response = await api.get(`/api/v1/auth/logout`);
            commit("CLEAR_TOKEN");
            return response;
        } catch (error) {
            return Promise.reject(error);
        }
    },

    async COUNTRIES() {
        try {
            const response = await api.get(`api/v1/country`);
            return response;
        } catch (error) {
            return Promise.reject(error);
        }
    },

    async IPAPI() {
        try {
            const response = await axios.get(`https://ipapi.co/json`, { timeout: 5000 });
            return response;
        } catch (error) {
            return Promise.reject(error);
        }
    },

    async GMT_COUNTRIES() {
        try {
            const response = await api.get(`api/v1/country/gmt`);
            return response;
        } catch (error) {
            return Promise.reject(error);
        }
    },

    

    async MODULE() {
        try {
            const response = await api.get(`api/v1/module`);
            return response;
        } catch (error) {
            return Promise.reject(error);
        }
    },


};


// exportar propiedades
export const auth = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
