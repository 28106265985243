import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
// import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "about" */ '../components/principal/Home.vue')
  },
  {
    path: '/checkout',
    name: 'checkout',
    component: () => import(/* webpackChunkName: "about" */ '../components/principal/checkout.vue')
  },
  {
    path: '/show_content',
    name: 'show_content',
    meta: {
      requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "about" */ '../components/principal/ShowContent.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "about" */ '../components/auth/Login.vue')
  },
  {
    path: '/password_setup',
    name: 'passwordSetup',
    component: () => import(/* webpackChunkName: "about" */ '../components/auth/PasswordSetup.vue')
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import(/* webpackChunkName: "about" */ '../components/auth/Register.vue')
  },
  {
    path: '/experience',
    name: 'Experience',
    component: () => import(/* webpackChunkName: "about" */ '../components/auth/Experience.vue')
  },
  {
    path: '/subscription',
    name: 'Subscription',
    meta: {
      requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "about" */ '../components/subscription/Subscription.vue')
  },
  {
    path: '/autentication_code',
    name: 'AutenticationCode',
    component: () => import(/* webpackChunkName: "about" */ '../components/AutenticationCode/AutenticationCode.vue')
  },
  {
    path: '/recovery_password',
    name: 'recovery_password',
    component: () => import(/* webpackChunkName: "about" */ '../components/AutenticationCode/RecoveryPassword.vue')
  },
  {
    path: '/profile',
    name: 'profile',
    meta: {
      requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "about" */ '../components/Profile/Profile.vue')
  },
  {
    path: '/profile/checkout',
    name: 'checkout_auth',
    meta: {
      requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "about" */ '../components/Profile/checkout_auth.vue')
  },
  {
    path: '/my_content',
    name: 'my_content',
    meta: {
      requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "about" */ '../components/Profile/MyContent.vue')
  },
  {
    path: '/my_way',
    name: 'my_way',
    meta: {
      requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "about" */ '../components/Profile/MyWay.vue')
  },
  {
    path: '/my_favorites',
    name: 'my_favorites',
    meta: {
      requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "about" */ '../components/Profile/MyFavorites.vue')
  },
  {
    path: '/my_history',
    name: 'my_history',
    meta: {
      requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "about" */ '../components/Profile/MyHistory.vue')
  },
  {
    path: '/detail_module',
    name: 'detail_module',
    meta: {
      requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "about" */ '../components/Profile/DetailModule.vue')
  },
  {
    path: '/my_profile',
    name: 'my_profile',
    meta: {
      requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "about" */ '../components/Profile/MyProfile.vue')
  },
  {
    path: '/select_days',
    name: 'select_days',
    meta: {
      requiresAuth: false
    },
    component: () => import(/* webpackChunkName: "about" */ '../components/Profile/SelectDays.vue')
  },
  {
    path: '/conditions',
    name: 'conditions',
    meta: {
      requiresAuth: false
    },
    component: () => import(/* webpackChunkName: "about" */ '../components/Conditions/Conditions.vue')
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  scrollBehavior() {
    return {
      x: 0,
      y: 0
    };
  },
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  const authUser = store.getters["auth/AUTHENTICATED"];
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!authUser) next({ path: "/login" });
    else next();
  } else {
    next(); // make sure to always call next()!
  }
});


export default router
